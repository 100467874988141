<template>
  <HeaderComponent :section-class="'hero-section-bio'" :show-text="false" />
  <div class="container">
    <div class="row d-flex justify-content-center pt-5">
      <div class="col-lg-4 order-0 order-lg-1">
        <div class="text-center mt-3 mb-3">
          <img
            src="../assets/images/michael-bio.png"
            class="img img-fluid rounded shadow"
          />
        </div>
      </div>
      <div class="col-lg-8 order-1 order-lg-0">
        <CardComponent>
          <div class="text-center">
            <h4 class="mt-2 mb-0"><strong>Michael Piccoli</strong></h4>
            <span>Founder and Principal Coach</span>
            <br /><br />

            <div class="px-4 mt-1">
              <p>
                I have a passion for fitness and health and wellness. I believe
                whole body lifestyle transformation, total wellness, nutrition,
                motivational coaching, and personal training one-on-one with
                clients are key ingredients to living a happy and healthy life.
                From this approach, I've transformed my physique and life.
              </p>
              <p>
                I have worked with personal trainers and coaches for over two
                decades. I've competed in several NPC Men's Physique
                competitions and won. I've learned the techniques and styles
                from my trainers and applied them to my own workouts. I've
                applied strength training, core conditioning, mind & muscle
                connection and mind & body connection into my own transformation
                and now I'd love to apply these techniques to your
                transformation.
              </p>
              <p>
                As a dedicated NASM Certified Personal Trainer and a Bioforce
                Certified Conditioning Coach, I specialize in strength,
                conditioning, kettlebell, holistic, and functional training. I
                work on abs & core development and strengthening, and HIIT
                techniques for a total body training experience.
              </p>
              <p>
                As a NASM Certified Nutrition Coach, I focus on healthy
                nutritional choices and changes within your diet that lead to
                healthy eating behaviors.
              </p>
              <p>
                I coach on health and life with a focus on mindset motivation,
                goal setting, accountability, mind and body connection, and
                overall positive lifestyle health and wellness.
              </p>
              <p>
                I'd love to help you achieve your fitness and health and
                wellness goals and ultimately reach your highest potential.
              </p>
            </div>

            <div class="col-12 mt-2">
              <h5 class="mt-2 mb-0">Specialties</h5>
            </div>

            <div class="col-12 mt-2 d-flex justify-content-center">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">One-on-one personal training</li>
                <li class="list-group-item">NASM Certified Personal Trainer</li>
                <li class="list-group-item">
                  NASM Certified Physique and Bodybuilding Coach
                </li>
                <li class="list-group-item">
                  Bioforce Certified Conditioning Coach
                </li>
                <li class="list-group-item">NASM Certified Nutrition Coach</li>
                <li class="list-group-item">Diet and nutrition coaching</li>
                <li class="list-group-item">
                  Online health, wellness, and life coaching
                </li>
                <li class="list-group-item">Mindset motivation coaching</li>
              </ul>
            </div>

            <div class="col-12">
              <a href="https://instagram.com/mjplyfe?igshid=YTQwZjQ0NmI0OA==">
                <img class="m-2" src="@/assets/svg/instagram.svg" />
              </a>
              <a href="mailto:michaelpiccoli@msn.com">
                <img class="m-1" src="@/assets/svg/mail.svg" />
              </a>
            </div>

            <div class="col-12 mt-2">
              <router-link
                to="/contact"
                class="btn btn-dark px-4 ms-3 text-primary"
                >Contact</router-link
              >
            </div>
          </div>
        </CardComponent>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "./core/Card.vue";
import HeaderComponent from "./core/Header.vue";

export default {
  name: "BioComponent",
  components: {
    CardComponent,
    HeaderComponent,
  },
};
</script>
